import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import {
  Button,
  Container,
  Box,
  Typography,
  Paper,
  Grid,
} from '@material-ui/core'
import { Lock } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import PropTypes from 'prop-types'
import * as yup from 'yup'

import { Form, Input, InputPassword } from '~/components/Form'
import { setToken } from '~/services/auth'
import Auth from '~/services/ubertrans-api/auth'

import { PageStyled } from './styles'

function Login({ history }) {
  const [error, setError] = useState(false)
  const userlogged = useSelector((state) => state.user)

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('E-mail precisa ser válido')
      .required('E-mail obrigatório'),
    password: yup.string().required('Senha obrigatória'),
  })

  useEffect(() => {
    if (!!userlogged.id && !error) {
      history.push('/')
    }
  }, [error, history, userlogged])

  const methods = useForm({ validationSchema })
  const { handleSubmit } = methods

  const onSubmit = (values) => {
    setError(false)
    Auth.login({
      email: `${values.email}`,
      password: values.password,
    })
      .then(async (response) => {
        await setToken(response)
        history.push('/')
      })
      .catch(({ response }) => {
        if (response?.status === 401) {
          setError(true)
        }
      })
  }

  return (
    <PageStyled>
      <Container component={Paper} maxWidth="xs">
        <Box display="flex" alignItems="center" flexDirection="column" mt={2}>
          <Lock color="primary" fontSize="large" />
          <Typography>Ubertrans</Typography>
        </Box>

        <Form onSubmit={handleSubmit(onSubmit)} methods={methods}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                name="email"
                label="E-mail"
                type="email"
                labelWidth={50}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <InputPassword
                name="password"
                label="Senha"
                labelWidth={50}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Entrar
              </Button>
            </Grid>
          </Grid>
        </Form>
        <Box p={2}>
          {error && <Alert severity="error">Email ou senha invalidos</Alert>}
        </Box>
      </Container>
    </PageStyled>
  )
}

Login.propTypes = {
  history: PropTypes.object.isRequired,
}

export default Login
