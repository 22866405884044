import Api from './api'

const Indicators = {
  getFilters() {
    return Api.request('/filtersAvaliable')
  },
  indicators(month, year) {
    return Api.request(`/indicators?month=${month}&year=${year}`)
  },
}

export default Indicators
