import React from 'react'

import { Button as MuiButton, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import { GridStyled } from './styles'

function Button({
  justify,
  xs,
  sm,
  md,
  lg,
  xl,
  mt,
  mr,
  mb,
  ml,
  children,
  variant,
  color,
  ...rest
}) {
  return (
    <GridStyled container justify={justify} mt={mt} mr={mr} mb={mb} ml={ml}>
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <MuiButton
          variant={variant}
          color={color}
          fullWidth
          size="large"
          {...rest}
        >
          {children}
        </MuiButton>
      </Grid>
    </GridStyled>
  )
}

Button.defaultProps = {
  variant: 'contained',
  color: 'primary',
  xs: 6,
  sm: null,
  md: null,
  lg: null,
  xl: null,
  mt: 24,
  mr: 0,
  mb: 0,
  ml: 0,
  justify: 'center'
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  justify: PropTypes.string
}

export default Button
