import React from 'react'

import { MonetizationOn } from '@material-ui/icons'
import PropTypes from 'prop-types'

import { formatCurrency } from '~/commons/utils/money'
import { Typography } from '~/components'

function TextAmount({ text, moneyOn }) {
  return (
    <Typography
      variant="body2"
      color={moneyOn ? 'primary' : 'error'}
      style={{ fontWeight: 500 }}
    >
      <MonetizationOn
        fontSize="small"
        color={moneyOn ? 'primary' : 'error'}
        style={{ verticalAlign: 'sub' }}
      />
      {formatCurrency(text)}
    </Typography>
  )
}

export default TextAmount

TextAmount.propTypes = {
  text: PropTypes.string.isRequired,
  moneyOn: PropTypes.bool.isRequired,
}
