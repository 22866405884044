import React from 'react'

import {
  Home,
  ViewList,
  Group,
  AccountCircle,
  LocalAtm,
} from '@material-ui/icons'

const Menus = [
  {
    title: 'Home',
    icon: <Home />,
    url: '/',
  },
  {
    title: 'Planos',
    icon: <LocalAtm />,
    url: '/plan',
  },
  {
    title: 'Pedidos',
    icon: <ViewList />,
    url: '/order',
  },
  {
    title: 'Clientes',
    icon: <Group />,
    url: '/client',
  },
  {
    title: 'Usuários',
    icon: <AccountCircle />,
    url: '/user',
  },
]

export default Menus
