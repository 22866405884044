import Api from './api'

const path = '/user'

const plan = {
  getAll() {
    return Api.request(`${path}`)
  },
  show(id) {
    return Api.request(`${path}/${id}`)
  },
  createOrUpdate(data) {
    if (data.id) {
      return Api.request(`${path}/${data.id}`, { data, method: 'PUT' })
    }
    return Api.request(path, { data, method: 'POST' })
  },
  deleteById(id) {
    return Api.request(`${path}/${id}`, { method: 'DELETE' })
  },
}

export default plan
