import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  meRequest: [],
  meSuccess: ['data'],
  meDefault: []
})

const request = state => state

const success = (state, action) => ({
  ...state,
  ...action.data
})

const defaults = state => state

export default createReducer(
  {},
  {
    [Types.ME_REQUEST]: request,
    [Types.ME_SUCCESS]: success,
    [Types.ME_DEFAULT]: defaults
  }
)
