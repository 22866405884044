import React from 'react'

import { Select as MuiSelect } from '@material-ui/core'
import PropTypes from 'prop-types'

import { withHookForm } from '~/components/Form/HookForm'

function Select({ children, ...rest }) {
  return <MuiSelect {...rest}>{children}</MuiSelect>
}

Select.propTypes = {
  children: PropTypes.node.isRequired
}

export { Select }
export default withHookForm(Select)
