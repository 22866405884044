import React, { useState, useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'

import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { ExpandLess, ExpandMore, ExitToApp } from '@material-ui/icons'

import MenuList from '~/commons/constants/menus'
import { removeToken } from '~/services/auth'

import SubMenus from '../SubMenus'

function Menus() {
  const [menus, setMenus] = useState(MenuList)
  const history = useHistory()

  useEffect(() => {
    setMenus((oldMenus) => {
      const updatedMenus = oldMenus.map((oldMenu) => {
        return { ...oldMenu, open: false }
      })
      return updatedMenus
    })
  }, [])

  const hasSubMenu = (menu) => {
    if (!menu.subMenus) {
      return history.push(menu.url)
    }

    return setMenus((oldMenus) => {
      const updatedMenu = oldMenus.map((oldMenu) => {
        return { ...oldMenu, open: !oldMenu.open }
      })
      return updatedMenu
    })
  }

  const logout = async () => {
    // await authApi.logout()  For the jwt schemes
    return removeToken()
  }

  return (
    <List component="nav">
      {menus.map((menu) => (
        <Fragment key={menu.title}>
          <ListItem button onClick={() => hasSubMenu(menu)}>
            <ListItemIcon>{menu.icon}</ListItemIcon>
            <ListItemText primary={menu.title} />
            {!!menu.subMenus && (menu.open ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          {!!menu.subMenus && (
            <SubMenus menus={menu.subMenus} show={menu.open} />
          )}
        </Fragment>
      ))}
      <Fragment key="Sair">
        <ListItem button onClick={() => logout()}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>
      </Fragment>
    </List>
  )
}

export default Menus
