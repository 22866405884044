import React from 'react'
import { useHistory } from 'react-router-dom'

import PropTypes from 'prop-types'

import { Button } from '~/components'

function Link({ to, text }) {
  const history = useHistory()
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => history.push(to)}
      fullWidth
    >
      {text}
    </Button>
  )
}

export default Link

Link.defaultProps = {
  text: 'Abrir',
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string,
}
