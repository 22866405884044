import Api from './api'

const Auth = {
  login(data) {
    return Api.request(`/auth`, { data, method: 'POST' })
  },

  logout(data) {
    return Api.request(`/logout`, { data, method: 'DELETE' })
  },
}

export default Auth
