import { List, CreateOrUpdate } from '~/pages/Plan'

const route = [
  {
    path: '/plan',
    element: List,
  },
  {
    path: '/plan/create',
    element: CreateOrUpdate,
  },
  {
    path: '/plan/:idPlan',
    element: CreateOrUpdate,
  },
]

export default route
