import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#B5E2E9'
    },
    background: {
      main: '#F2FCFF'
    }
  },
  shape: {
    drawerWidth: 240
  }
})

export default theme
