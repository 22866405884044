import { Drawer } from '@material-ui/core'
import styled from 'styled-components'

const styles = theme => ({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  }
})

export const Container = styled(Drawer)`
  ${({ theme }) => `
  width: ${theme.shape.drawerWidth}px;
  flex-shrink: 0;

  .MuiDrawer-paper {
    width: ${theme.shape.drawerWidth}px;
  }
`}
`

export const ContainerHeader = styled.div`
  ${({ theme }) => styles(theme).drawerHeader}
`
