import styled from 'styled-components'

const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: theme.shape.drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
})

export const Container = styled.main`
  ${({ theme, open }) => {
    const classes = styles(theme)

    return {
      ...classes.content,
      ...(open || classes.contentShift),
    }
  }}
`

export const Header = styled.div`
  ${({ theme }) => styles(theme).drawerHeader}
`
