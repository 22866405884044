import React from 'react'

import { Divider } from '@material-ui/core'
import PropTypes from 'prop-types'

import Menus from './components/Menus'
import { Container, ContainerHeader } from './styles'

function Sidebar({ open }) {
  return (
    <Container variant="persistent" anchor="left" open={open}>
      <ContainerHeader />
      <Divider />
      <Menus />
    </Container>
  )
}

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired
}

export default Sidebar
