import React from 'react'
import { useHistory } from 'react-router-dom'

import { List, ListItemText, Collapse, ListItemIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

import { ListItem } from './styles'

function SubMenu({ menus, show }) {
  const history = useHistory()

  return (
    <Collapse in={show} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {menus.map(menu => (
          <ListItem
            button
            key={menu.title}
            onClick={() => history.push(menu.url)}
          >
            <ListItemIcon>{menu.icon}</ListItemIcon>
            <ListItemText primary={menu.title} />
          </ListItem>
        ))}
      </List>
    </Collapse>
  )
}

SubMenu.defaultProps = {
  show: false
}

SubMenu.propTypes = {
  menus: PropTypes.array.isRequired,
  show: PropTypes.bool
}

export default SubMenu
