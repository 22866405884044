import { List, Detail } from '~/pages/Orders'

const route = [
  {
    path: '/order',
    element: List,
  },
  {
    path: '/order/:idOrder',
    element: Detail,
  },
]

export default route
