import React from 'react'

import PropTypes from 'prop-types'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '~/components'

function Modal({ title, text, children, actions, ...rest }) {
  return (
    <Dialog fullWidth {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {text && <DialogContentText>{text}</DialogContentText>}
        {children}
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  )
}

Modal.defaultProps = {
  title: null,
  text: null,
  children: null,
  actions: null
}

Modal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.node
}

export default Modal
