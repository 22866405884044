import React from 'react'

import { AccessTime as AccessTimeIcon } from '@material-ui/icons'
import PropTypes from 'prop-types'

import { Typography } from '~/components'

import SelectorStyled from './styles'

function SelectorInterval({ text, value, activeComponent, onClickCallback }) {
  return (
    <SelectorStyled
      active={activeComponent}
      onClick={() => onClickCallback(value)}
    >
      <Typography variant="body2">
        <AccessTimeIcon
          fontSize="inherit"
          style={{ verticalAlign: 'text-top' }}
        />{' '}
        {text}
      </Typography>
    </SelectorStyled>
  )
}

export default SelectorInterval

SelectorInterval.defaultProps = {
  activeComponent: 'false',
}

SelectorInterval.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  activeComponent: PropTypes.bool,
  onClickCallback: PropTypes.func.isRequired,
}
