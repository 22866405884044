const userColunms = [
  {
    title: 'ID',
    field: 'id',
  },
  {
    title: 'E-mail',
    field: 'email',
  },
]

export { userColunms }
