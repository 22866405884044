import { getLanguage } from '~/commons/utils/locale'

import moment from './moment'

const REMOVE_OFFSET = -1

const getNow = moment

const getDate = (date, format) => moment(date, format)

const formatDateUTC = (date, format = 'L') =>
  date ? moment.utc(date).format(format) : null

const formatDate = (date, format = 'L', originalFormat) =>
  date ? moment.utc(date, originalFormat).local().format(format) : null

const getDiffDate = (date, unity = 'days') => getDate().diff(date, unity)

const getHumanizedRange = (data) =>
  moment.duration(getDate(data).diff(getNow())).humanize()

const getShortDate = (date) => {
  const isShortInterval = getDiffDate(date, 'days') >= 2

  return isShortInterval
    ? getDate(date).format('DD/MM')
    : getHumanizedRange(date)
}

const isValidDate = (date, format = 'L') => moment(date, format).isValid()

const convertDateToUTC = (date) => moment.utc(date).valueOf()

const formatToISO = (date) => moment(date).toISOString()

const subtractDays = (subtract, date = getNow()) =>
  date.startOf('day').subtract(subtract, 'days')

const addDays = (add, date = getNow()) => date.startOf('day').add(add, 'days')

const getStartDay = (date) => moment(date).startOf('day')

const getEndDay = (date) => moment(date).endOf('day')

const startOf = (date, param) => moment(date).startOf(param)

const endOf = (date, param) => moment(date).endOf(param)

const getAbbreviatedDate = (date, locale = getLanguage()) =>
  formatDate(date, locale === 'en-US' ? 'MM/DD/YY' : 'DD/MM/YY')

const getMinutesFormat = (hour, minute) => {
  const manyDecimal = minute > 9
  const validMinute = minute || '0'
  const minuteWithZero = hour ? `0${minute}` : validMinute

  return manyDecimal ? minute : minuteWithZero
}

const getSecondsFormat = (seconds) => {
  const s = seconds % 60

  return s > 9 ? s : `0${s}`
}

const formatTime = (seconds) => {
  const hour = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)

  return [hour, getMinutesFormat(hour, m), getSecondsFormat(seconds)]
    .filter((a) => a)
    .join(':')
}

const getUserTimezone = () => new Date().getTimezoneOffset() * REMOVE_OFFSET

const isBefore = (initialDate, finalDate, format) =>
  moment(initialDate, format).isBefore(getDate(finalDate, format))

export {
  getNow,
  getDate,
  formatDate,
  formatDateUTC,
  getDiffDate,
  getHumanizedRange,
  getShortDate,
  isValidDate,
  convertDateToUTC,
  formatToISO,
  subtractDays,
  addDays,
  getStartDay,
  getEndDay,
  startOf,
  endOf,
  getAbbreviatedDate,
  formatTime,
  getUserTimezone,
  isBefore,
}
