import { List, CreateOrUpdate } from '~/pages/User'

const route = [
  {
    path: '/user',
    element: List,
  },
  {
    path: '/user/create',
    element: CreateOrUpdate,
  },
  {
    path: '/user/:idUser',
    element: CreateOrUpdate,
  },
]

export default route
