import React from 'react'

import { AddCircle, MonetizationOn, MoneyOff, Error, CheckCircle } from '@material-ui/icons'

const orderColors = {
  CREATED: 'primary',
  ORDER_APPROVED: 'primary',
  PAYMENT_APPROVED: 'primary',
  ERROR: 'secondary',
}

const orderIcons = {
  CREATED: <AddCircle />,
  PAYMENT_APPROVED: <MonetizationOn />,
  UNAUTHORIZED_PAYMENT: <MoneyOff />,
  ERROR: <Error />,
  ORDER_APPROVED: <CheckCircle />,
}

const getOrderColor = (status) => orderColors[status] || orderColors.ERROR

const getOrderIcon = (status) => orderIcons[status] || orderIcons.ERROR

export { getOrderColor, getOrderIcon }
