import React from 'react'
import InputMask from 'react-input-mask'

import { OutlinedInput as MuiOutlinedInput } from '@material-ui/core'
import PropTypes from 'prop-types'

import { getMask } from '~/commons/utils/mask'
import { withHookForm } from '~/components/Form/HookForm'

function OutlinedInput({ mask, value, onChange, labelWidth, ...rest }) {
  return (
    <InputMask mask={getMask(mask)} value={value} onChange={onChange} {...rest}>
      {(inputProps) => (
        <MuiOutlinedInput {...inputProps} labelWidth={labelWidth} />
      )}
    </InputMask>
  )
}

export { OutlinedInput }
export default withHookForm(OutlinedInput)

OutlinedInput.defaultProps = {
  value: '',
  onChange: () => {},
  rest: {},
}

OutlinedInput.propTypes = {
  mask: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  labelWidth: PropTypes.number.isRequired,
  rest: PropTypes.object,
}
