import Login from '~/pages/Login'

const route = [
  {
    path: '/login',
    element: Login
  }
]

export default route
