const key = '@UBERTRANS-ADMIN/token'

const isAuthenticated = () => !!window.localStorage.getItem(key)

const setToken = async (token) =>
  window.localStorage.setItem(key, JSON.stringify(token))

const getToken = () => JSON.parse(window.localStorage.getItem(key))

const removeToken = async () => {
  await window.localStorage.removeItem(key)
  window.location.href = '/login'
}

export { isAuthenticated, getToken, setToken, removeToken }
