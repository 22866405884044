import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#353535'
    },
    secondary: {
      main: '#49FFA4'
    },
    background: {
      main: '#F2F2F2'
    },
    status: {
      primary: '#3498db',
      success: '#27ae60',
      danger: '#c0392b',
      warning: '#f39c12'
    }
  },
  shape: {
    drawerWidth: 240
  }
})

export default theme
