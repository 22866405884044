import { AppBar } from '@material-ui/core'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`

const drawerWidth = 240

const styles = theme => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  }
})

export const AppBarStyled = styled(AppBar)`
  ${({ theme, open }) => {
    const classes = styles(theme)
    return {
      ...classes.appBar,
      ...(!open || classes.appBarShift)
    }
  }}
`
