import React from 'react'

import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Divider,
} from '@material-ui/core'
import PropTypes from 'prop-types'

function CardWidget({ primary, secondary, footerText }) {
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="body2" component="span">
          {primary}
        </Typography>
        <Typography variant="h5" component="h2">
          {secondary}
        </Typography>
      </CardContent>
      <Divider />
      {!footerText || (
        <CardActions>
          <Typography variant="body2" component="p" color="primary">
            {footerText}
          </Typography>
        </CardActions>
      )}
    </Card>
  )
}

export default CardWidget

CardWidget.defaultProps = {
  footerText: false,
}

CardWidget.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired,
  footerText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}
