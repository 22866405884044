import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import PropTypes from 'prop-types'
import * as Yup from 'yup'

import { Page, PageContent, PageItem, Button, Grid } from '~/components'
import { Form, Input } from '~/components/Form'
import { userAdminApi } from '~/services/ubertrans-api'

function CreateOrUpdate({ match }) {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('E-mail inválido').required('E-mail obrigatório'),
    password: Yup.string().required('Senha obrigatória'),
    password_confirmation: Yup.string()
      .oneOf(
        [Yup.ref('password'), null],
        'Confirmação de senha precisa ser igual a senha'
      )
      .required('Confirmação da senha obrigatória'),
  })

  const { idUser } = match.params
  const history = useHistory()
  const PageTitle = idUser ? 'Usuário' : 'Novo Usuário'
  const btnText = idUser ? 'Salvar' : 'Adicionar'
  const methods = useForm({ validationSchema })
  const { handleSubmit, reset } = methods

  useEffect(() => {
    if (idUser) {
      userAdminApi.show(idUser).then((response) => {
        reset({ email: response.email })
      })
    }
  }, [idUser, reset])

  const onSubmit = (values) => {
    const data = {
      ...values,
      id: idUser,
    }
    userAdminApi
      .createOrUpdate(data)
      .then(() => {
        history.push('/user')
      })
      .catch(() => {})
  }

  return (
    <Page title={PageTitle}>
      <PageContent spacing={2}>
        <PageItem paper xs={6} style={{ padding: '4%', margin: 'auto' }}>
          <Form methods={methods}>
            <Grid container spacing={2}>
              <PageItem paper={false} xs={12}>
                <Input
                  type="email"
                  name="email"
                  labelWidth={50}
                  label="E-mail"
                  fullWidth
                />
              </PageItem>
              <PageItem paper={false} xs={12}>
                <Input
                  type="password"
                  name="password"
                  label="Senha"
                  labelWidth={50}
                  fullWidth
                />
              </PageItem>
              <PageItem paper={false} xs={12}>
                <Input
                  type="password"
                  name="password_confirmation"
                  label="Confirmação da Senha"
                  labelWidth={180}
                  fullWidth
                />
              </PageItem>
              <PageItem paper={false} xs={12}>
                <Button xs={6} onClick={handleSubmit(onSubmit)}>
                  {btnText}
                </Button>
              </PageItem>
            </Grid>
          </Form>
        </PageItem>
      </PageContent>
    </Page>
  )
}

CreateOrUpdate.propTypes = {
  match: PropTypes.object.isRequired,
}

export default CreateOrUpdate
