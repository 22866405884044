import React from 'react'

import { Input } from '@material-ui/core'

import { withHookForm } from '~/components/Form/HookForm'

function InputNumber(props) {
  return <Input {...props} type="number" />
}

export { InputNumber }
export default withHookForm(InputNumber)
