import React from 'react'

import { Tooltip as MuiTooltip, Zoom } from '@material-ui/core'
import PropTypes from 'prop-types'

function Tooltip({ children, ...rest }) {
  return (
    <MuiTooltip
      TransitionComponent={Zoom}
      enterDelay={250}
      leaveDelay={200}
      {...rest}
    >
      {children}
    </MuiTooltip>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired
}

export default Tooltip
