import { filterNotNums } from './string'

const mask = {
  bankDigit: '9',
  bankBranch: '99999',
  bankAccount: '999999999999',
  cnae: '9999-9/99',
  stateRegistration: '99999999999999',
  cpf: '999.999.999-99',
  cnpj: '99.999.999/9999-99',
  zipCode: '99999-999',
  cellPhone: '(99) 99999-9999',
  phone: '(99) 9999-9999',
  ncm_number: '9999.99.99',
  protocol_number: new Array(31).join('9'),
  date: '99/99/9999',
  expiryDate: '99/99',
  creditCardNumber: '9999 9999 9999 9999',
}

const unmask = {}

const getMask = (format) => mask[format] || format
const getUnmask = (format) => (value) =>
  (unmask[format] || filterNotNums)(value)

export { getMask, getUnmask }
