import { diacriticsMap } from './constant'

const filterNotNums = (value = '') => value.replace(/\D/g, '')

const escapeHTML = (text) =>
  text ? text.replace(/(<([^>]+)>)|&nbsp;/gi, '') : ''

const getUniqueId = () =>
  crypto.getRandomValues(new Uint32Array(13))[0].toString()

const ellipsis = (text, size) => {
  if (text && text.length > size) {
    return `${text.slice(0, size)}...`
  }

  return text
}

const formatPhone = (phone) => {
  if (!phone) {
    return ''
  }
  if (phone.length === 10) {
    return phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
  }
  return phone.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4')
}

const isPlural = (number) => number > 1

const getPluralized = (number) => (key) =>
  isPlural(number) ? `${key}-plural` : key

const formatZip = (zipCode) =>
  filterNotNums(zipCode).replace(/(\d{5})(\d{3})/, '$1-$2')

const formatCPF = (value) => {
  if (!value) return ''

  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
}

const formatCNPJ = (value) => {
  if (!value) return ''

  return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
}

const formatDocument = (value) => {
  if (!value) return ''

  if (value.length === 11) {
    return formatCPF(value)
  }

  return formatCNPJ(value)
}

const removeDiacritics = (str) => {
  if (!str) return ''

  return str.replace(/[^A-Za-z0-9\s]/g, (a) => diacriticsMap[a] || a)
}

const capitalize = (str) => {
  if (!str) return str

  return str.charAt(0).toUpperCase() + str.slice(1)
}

export {
  isPlural,
  getPluralized,
  filterNotNums,
  formatZip,
  escapeHTML,
  formatPhone,
  getUniqueId,
  ellipsis,
  formatCPF,
  formatCNPJ,
  formatDocument,
  removeDiacritics,
  capitalize,
}
