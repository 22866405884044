import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Fab } from '@material-ui/core'
import {
  Delete as DeleteIcon,
  Launch as LaunchIcon,
  Add as AddIcon,
} from '@material-ui/icons'

import {
  Page,
  PageContent,
  PageItem,
  Table,
  Paper,
  PageFooter,
} from '~/components'
import { planApi } from '~/services/ubertrans-api'

import DialogConfirm from './components/DialogConfirm'
import { orderColunms } from './constants'

function ListOrder() {
  const planTableRef = useRef()
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogData, setDialogData] = useState({})

  const history = useHistory()

  const handleClose = () => setOpenDialog(false)
  const handleConfirm = (data) => {
    planApi.deleteById(data.id).then(() => {
      return planTableRef.current && planTableRef.current.onQueryChange()
    })
    setOpenDialog(false)
  }

  return (
    <Page title="Planos de Recarga">
      <PageContent>
        <PageItem paper={false} xs={12} style={{ padding: '4%' }}>
          <Table
            title="Planos"
            tableRef={planTableRef}
            data={(query) => {
              return new Promise((resolve, reject) => {
                planApi
                  .getAll(query.page + 1, query.pageSize)
                  .then((response) => {
                    resolve({
                      data: response.data,
                      page: response.page - 1,
                      totalCount: response.total,
                    })
                  })
                  .catch((err) => reject(err))
              })
            }}
            options={{
              search: false,
              paging: true,
              toolbar: true,
              showTitle: true,
              actionsColumnIndex: -1,
              pageSize: 15,
              pageSizeOptions: [5, 10, 15, 25, 50],
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'Não há registros para mostrar',
              },
              header: {
                actions: 'Ações',
              },
            }}
            actions={[
              {
                icon: () => (
                  <Button variant="contained" color="primary">
                    <LaunchIcon />
                  </Button>
                ),
                tooltip: 'Abrir',
                onClick: (event, rowData) => {
                  history.push(`/plan/${rowData.id}`)
                },
              },
              {
                icon: () => (
                  <Button variant="contained" color="secondary">
                    <DeleteIcon />
                  </Button>
                ),
                tooltip: 'Deletar plano',
                onClick: (event, rowData) => {
                  setDialogData(rowData)
                  setOpenDialog(true)
                },
              },
            ]}
            columns={orderColunms}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
          />
        </PageItem>
        <DialogConfirm
          open={openDialog}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          data={dialogData}
        />
      </PageContent>
      <PageFooter>
        <Fab
          color="primary"
          aria-label="add"
          style={{ float: 'right' }}
          onClick={() => history.push('/plan/create')}
        >
          <AddIcon />
        </Fab>
      </PageFooter>
    </Page>
  )
}

export default ListOrder
