const formatToMoney = (number, options = {}, country = 'pt-BR') => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(number)) return ''

  const { style, currency = 'BRL', minimumFractionDigits = 2 } = options

  return new Intl.NumberFormat(country, {
    style,
    currency,
    minimumFractionDigits,
  }).format(number)
}

const formatCurrency = (price) => formatToMoney(price, { style: 'currency' })

const formatCurrencyInterger = (price) => {
  const castPrice = price / 100
  return formatToMoney(castPrice, { style: 'currency' })
}

export { formatToMoney, formatCurrency, formatCurrencyInterger }
