import React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'

import Loading from '~/components/Loading'

const getUniqueId = () =>
  crypto.getRandomValues(new Uint32Array(13))[0].toString()

const opened = []
let containerDomNode = null

const removeOpened = (id) => {
  const index = opened.indexOf(id)
  const hasItem = index > -1

  /* istanbul ignore else */
  if (hasItem) {
    opened.splice(index, 1)
  }
}

const hasOpened = () => opened.length > 0

const unmountComponent = () => {
  if (!hasOpened() && containerDomNode !== null) {
    unmountComponentAtNode(containerDomNode)

    document.body.removeChild(containerDomNode)

    containerDomNode = null
  }
}

const mountComponent = () => {
  if (!containerDomNode) {
    containerDomNode = document.createElement('div')
    containerDomNode.id = 'loading'

    document.body.appendChild(containerDomNode)

    render(<Loading full />, containerDomNode)
  }
}

const open = (options = {}) => {
  const uid = options.id || getUniqueId()

  opened.push(uid)

  mountComponent()

  return uid
}

const close = (id) => {
  removeOpened(id)
  unmountComponent()
}

export default {
  open,
  close,
}
