import React from 'react'

import PropTypes from 'prop-types'

import { Grid, Paper } from '~/components'

function PageItem({ children, paper, ...rest }) {
  return (
    <Grid item component={paper ? Paper : 'div'} {...rest}>
      {children}
    </Grid>
  )
}

PageItem.defaultProps = {
  paper: true
}

PageItem.propTypes = {
  children: PropTypes.node.isRequired,
  paper: PropTypes.bool
}

export default PageItem
