import React from 'react'

import { Page, PageContent, PageItem, Table, Paper } from '~/components'
import { orderApi } from '~/services/ubertrans-api'

import { orderColunms } from './constants'

function ListOrder() {
  return (
    <Page title="Pedidos">
      <PageContent>
        <PageItem paper={false} xs={12} style={{ padding: '4%' }}>
          <Table
            title="Lista de pedidos"
            data={(query) => {
              return new Promise((resolve, reject) => {
                orderApi
                  .getAll(query.page + 1, query.pageSize)
                  .then((response) => {
                    resolve({
                      data: response.data,
                      page: response.page - 1,
                      totalCount: response.total,
                    })
                  })
                  .catch((err) => reject(err))
              })
            }}
            options={{
              search: false,
              paging: true,
              toolbar: true,
              showTitle: true,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'Não há registros para mostrar',
              },
            }}
            columns={orderColunms}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
          />
        </PageItem>
      </PageContent>
    </Page>
  )
}

export default ListOrder
