import { List, Detail } from '~/pages/Client'

const route = [
  {
    path: '/client',
    element: List,
  },
  {
    path: '/client/:idClient',
    element: Detail,
  },
]

export default route
