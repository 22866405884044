import React, { useEffect, useState } from 'react'

import { Card, CardHeader, CardContent, Paper } from '@material-ui/core'

import i18n from '~/commons/i18n'
import { getNow } from '~/commons/utils/date'
import { formatCurrencyInterger } from '~/commons/utils/money'
import {
  Page,
  PageContent,
  PageItem,
  Grid,
  CardWidget,
  Table,
} from '~/components'
import { indicatorsApi } from '~/services/ubertrans-api'

import SelectorInterval from './components/SelectorInterval'
import { orderStatus, orderErrors } from './constants'

function Home() {
  const [indicatorsData, setIndicatorsData] = useState({})
  const [dateFilterAvaliable, setDateFilterAvaliable] = useState([])
  const [dateFilter, setDateFilter] = useState({
    month: getNow().format('MM'),
    year: getNow().format('yyyy'),
  })

  function handleFilterChange({ monthnumber, year }) {
    setDateFilter({ month: monthnumber, year })
  }

  useEffect(() => {
    if (dateFilter?.month === undefined) {
      return
    }
    indicatorsApi
      .indicators(dateFilter?.month, dateFilter?.year)
      .then((response) => setIndicatorsData(response))
  }, [dateFilter])

  useEffect(() => {
    indicatorsApi
      .getFilters()
      .then((response) => setDateFilterAvaliable(response))
  }, [])

  return (
    <Page title={i18n.t('home.home')}>
      <PageContent spacing={1}>
        <PageItem paper={false} xs={12} style={{ display: 'flex' }}>
          {dateFilterAvaliable.map((filter, idx) => (
            <SelectorInterval
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              text={`${filter.monthnumber}/${filter.year}`}
              value={filter}
              activeComponent={
                !!(
                  Number(dateFilter.month) === filter.monthnumber &&
                  Number(dateFilter.year) === filter.year
                )
              }
              onClickCallback={(e) => handleFilterChange(e)}
            />
          ))}
        </PageItem>
        <PageItem paper={false} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <CardWidget
                primary="Pedidos"
                secondary={indicatorsData?.totalOrders}
                footerText="Número total de pedidos"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CardWidget
                primary="Total Faturado"
                secondary={formatCurrencyInterger(indicatorsData?.totalAmount)}
                footerText="Pedidos faturados e completados"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CardWidget
                primary="Valor médio"
                secondary={formatCurrencyInterger(indicatorsData?.avgAmount)}
                footerText="Valor médio dos pedidos faturados"
              />
            </Grid>
          </Grid>
        </PageItem>
        <PageItem paper={false} xs={12} sm={4}>
          <Card>
            <CardHeader title="Pedidos por Status" />
            <CardContent>
              <Table
                data={indicatorsData?.ordersByStatus}
                options={{
                  search: false,
                  paging: false,
                  toolbar: false,
                  showTitle: false,
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: 'Não há registros para mostrar',
                  },
                }}
                columns={orderStatus}
                components={{
                  Container: (props) => <Paper {...props} elevation={0} />,
                }}
              />
            </CardContent>
          </Card>
        </PageItem>
        <PageItem paper={false} xs={12} sm={8}>
          <Card>
            <CardHeader title="Pedidos com Erro" />
            <CardContent>
              <Table
                data={indicatorsData?.ordersWithErrors}
                options={{
                  search: false,
                  paging: true,
                  toolbar: false,
                  showTitle: false,
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: 'Não há registros para mostrar',
                  },
                }}
                columns={orderErrors}
                components={{
                  Container: (props) => <Paper {...props} elevation={0} />,
                }}
              />
            </CardContent>
          </Card>
        </PageItem>
      </PageContent>
    </Page>
  )
}

export default Home
