import client from './client.json'
import general from './general.json'
import home from './home.json'
import invoice from './invoice.json'
import order from './order.json'

export default {
  general,
  home,
  invoice,
  order,
  client,
}
