import Home from '~/pages/Home'

const route = [
  {
    path: '/',
    element: Home
  }
]

export default route
