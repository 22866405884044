import { removeToken } from '~/services/auth'

const STATUS = {
  400: 'BAD_REQUEST',
  401: 'UNAUTHENTICATED',
  404: 'NOT_FOUND',
  500: 'FATAL_ERROR',
}

const errors = {
  UNAUTHENTICATED: () => {
    if (window.location.pathname !== '/login') {
      removeToken()
    }
  },
  FATAL_ERROR: () => {},
  NOT_FOUND: () => {},
  BAD_REQUEST: () => {},
}

const buildError = ({ response }) => {
  if (response?.status !== undefined) {
    const status = STATUS[response.status]
    errors[status]()
  }

  return errors.FATAL_ERROR()
}

const onResponseError = (error) => {
  buildError(error)
  return Promise.reject(error)
}

const validateRequest = (config) => config

export { onResponseError, validateRequest }
