import React from 'react'
import { useHistory } from 'react-router-dom'

import { Box, Typography, Button } from '@material-ui/core'

function NotFound() {
  const history = useHistory()
  return (
    <Box marginLeft="45%" marginTop="10%">
      <Typography variant="h6">Pagina não existe!</Typography>
      <Box marginTop={1} alignContent="center">
        <Button onClick={() => history.push('/')}>Voltar para o inicio</Button>
      </Box>
    </Box>
  )
}

export default NotFound
