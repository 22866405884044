import React from 'react'

import i18n from '~/commons/i18n'
import { formatDate } from '~/commons/utils/date'
import { formatCurrencyInterger } from '~/commons/utils/money'
import { Link } from '~/components'

const orderStatus = [
  {
    title: 'Status',
    render: (row) => {
      return i18n.t(`order.chips.${row.status}`)
    },
  },
  {
    title: 'Qtde',
    field: 'sum',
  },
]

const orderErrors = [
  {
    title: 'ID',
    field: 'external_id',
  },
  {
    title: 'ID E1',
    field: 'id_order_e1',
  },
  {
    title: 'Data',
    render: (row) => formatDate(row.created_at, 'DD/MM/YYYY HH:mm:ss'),
  },
  {
    title: 'Valor',
    render: (row) => {
      return formatCurrencyInterger(row.amount)
    },
  },
  {
    title: 'Erro',
    render: (row) => {
      return row.message.match(/""(.*?)""/)[1]
    },
  },
  {
    title: '',
    render: (row) => <Link to={`/order/${row.external_id}`} />,
  },
]

export { orderStatus, orderErrors }
