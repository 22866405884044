import React from 'react'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))

export default function CardWithCollapse({ cardContent, cardContentHidden }) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>{cardContent}</CardContent>
      <CardActions disableSpacing>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="mostrar mais"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      {!cardContentHidden || (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>{cardContentHidden}</CardContent>
        </Collapse>
      )}
    </Card>
  )
}

CardWithCollapse.propTypes = {
  cardContent: PropTypes.node.isRequired,
  cardContentHidden: PropTypes.node.isRequired,
}
