import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import {
  FormControl as MuiFormControl,
  InputLabel,
  FormHelperText,
  InputAdornment,
} from '@material-ui/core'
import PropTypes from 'prop-types'

function FormControl({ customComponent: Input, ...rest }) {
  const { errors, control } = useFormContext()
  const {
    label,
    name,
    helperText,
    fullWidth,
    autoComplete = 'off',
    margin = 'none',
    startAdornment = false,
    endAdornment = false,
    ...restProps
  } = rest
  const helperTextId = `helper-text-${name}`
  const error = errors[name]
  const message = !error ? helperText : error?.message
  const adornment = (icon) => <InputAdornment>{icon}</InputAdornment>
  const defaultValue = control.defaultValuesRef.current[name] || ''

  return (
    <MuiFormControl fullWidth={fullWidth} margin={margin} error={!!error}>
      {label && (
        <InputLabel variant="outlined" htmlFor={name}>
          {label}
        </InputLabel>
      )}
      <Controller
        as={
          <Input
            id={name}
            aria-describedby={helperTextId}
            autoComplete={
              typeof autoComplete === 'string' ? autoComplete : 'on'
            }
            startAdornment={startAdornment && adornment(startAdornment)}
            endAdornment={endAdornment && adornment(endAdornment)}
            {...restProps}
          />
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      {message && <FormHelperText id={helperTextId}>{message}</FormHelperText>}
    </MuiFormControl>
  )
}

FormControl.propTypes = {
  customComponent: PropTypes.elementType.isRequired,
}

export default FormControl
