import React, { useState } from 'react'

import { Toolbar, IconButton, Typography } from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import PropTypes from 'prop-types'

import { Sidebar } from '~/components'

import { Container, AppBarStyled } from './styles'

function Navbar({ title, children, isOpen }) {
  const [openSidebar, setOpenSidebar] = useState(false)

  isOpen(openSidebar)

  return (
    <Container>
      <AppBarStyled position="fixed" open={openSidebar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open sidebar"
            onClick={() => setOpenSidebar(!openSidebar)}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          {!title || (
            <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
              {title}
            </Typography>
          )}
          {children}
        </Toolbar>
      </AppBarStyled>
      <Sidebar open={openSidebar} />
    </Container>
  )
}

Navbar.defaultProps = {
  title: '',
  children: '',
}

Navbar.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  isOpen: PropTypes.func.isRequired,
}

export default Navbar
