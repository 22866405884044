import { Paper } from '@material-ui/core'
import styled from 'styled-components'

const PaperStyled = styled(Paper)`
  scroll-snap-align: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  line-height: 1.17;
  margin-right: 5px;
  height: 39px;
  max-width: 160px;
  border-radius: 20px;
  padding: 10px;
  ${({ theme, active }) => {
    const isActive = !!active
    return `
      color: ${isActive ? '#fff' : 'rgba(0, 0, 0, 0.87)'};
      background-color: ${isActive ? theme.palette.primary.main : '#fff'}
      `
  }}
`

export default PaperStyled
