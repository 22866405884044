import React from 'react'
import { Provider } from 'react-redux'

import themes from '~/commons/constants/themes'
import Ubertrans from '~/components/Ubertrans'
import Routes from '~/routes'
import '~/config/reactotron/'
import store from '~/store'

function App() {
  return (
    <Provider store={store}>
      <Ubertrans theme={themes.defaultTheme}>
        <Routes />
      </Ubertrans>
    </Provider>
  )
}

export default App
