import React from 'react'

import PropTypes from 'prop-types'

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Paper,
  Button,
} from '~/components'

function DialogConfirm({ open, handleClose, handleConfirm, data }) {
  return (
    <Dialog open={open} onClose={handleClose} PaperComponent={Paper}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Alerta
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Confirma exclusão deste registro?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          xs={8}
          fullWidth
          size="small"
          color="secondary"
        >
          Cancelar
        </Button>
        <Button
          onClick={() => handleConfirm(data)}
          size="small"
          color="primary"
          xs={8}
          fullWidth
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogConfirm

DialogConfirm.propTypes = {
  open: PropTypes.shape.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  data: PropTypes.shape.isRequired,
}
