import React from 'react'

import { Done as DoneIcon, Close as CloseIcon } from '@material-ui/icons'

import { formatCurrencyInterger } from '~/commons/utils/money'

const orderColunms = [
  {
    title: 'Plano',
    field: 'name',
  },
  {
    title: 'ID do Tipo de Cartão',
    field: 'type_card_id',
  },
  {
    title: 'Valor',
    render: (row) => formatCurrencyInterger(row.amount),
  },
  {
    title: 'Ativo',
    render: (row) =>
      row.active ? (
        <DoneIcon color="primary" />
      ) : (
        <CloseIcon color="secondary" />
      ),
  },
]

export { orderColunms }
