import React from 'react'

import { OutlinedInput as MuiInput } from '@material-ui/core'

import { withHookForm } from '~/components/Form/HookForm'

function Input(props) {
  return <MuiInput {...props} />
}

export { Input }
export default withHookForm(Input)
