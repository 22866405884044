import React from 'react'

import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

function ListText({ primaryText, captionText }) {
  return (
    <>
      <Typography variant="body2" color="textSecondary">
        {captionText}
      </Typography>
      <Typography variant="body1">{primaryText}</Typography>
    </>
  )
}

ListText.propTypes = {
  primaryText: PropTypes.node.isRequired,
  captionText: PropTypes.node.isRequired,
}

export default ListText
