import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import PropTypes from 'prop-types'
import * as Yup from 'yup'

import { Page, PageContent, PageItem, Button, Grid } from '~/components'
import { Form, Input } from '~/components/Form'
import { planApi } from '~/services/ubertrans-api'

function CreateOrUpdate({ match }) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome do plano obrigatório'),
    amount: Yup.number('Valor tem que ser um número').required(
      'Valor obrigatório'
    ),
    type_card_id: Yup.number('Tipo do cartão tem que ser um número').required(
      'Tipo de cartão obrigatório'
    ),
  })

  const { idPlan } = match.params
  const history = useHistory()
  const PageTitle = idPlan ? 'Plano de recarga' : 'Novo Plano de recarga'
  const btnText = idPlan ? 'Salvar' : 'Adicionar'
  const methods = useForm({ validationSchema })
  const { handleSubmit, reset } = methods

  useEffect(() => {
    if (idPlan) {
      planApi.show(idPlan).then((response) => {
        reset({ ...response, amount: response.amount / 100 })
      })
    }
  }, [idPlan, reset])

  const onSubmit = (values) => {
    const data = {
      ...values,
      amount: Math.round(values.amount * 100),
      id: idPlan,
    }
    planApi
      .createOrUpdate(data)
      .then(() => {
        history.push('/plan')
      })
      .catch(() => {})
  }

  return (
    <Page title={PageTitle}>
      <PageContent spacing={2}>
        <PageItem paper xs={6} style={{ padding: '4%', margin: 'auto' }}>
          <Form methods={methods}>
            <Grid container spacing={2}>
              <PageItem paper={false} xs={12}>
                <Input name="name" labelWidth={50} label="Plano" fullWidth />
              </PageItem>
              <PageItem paper={false} xs={12}>
                <Input
                  type="number"
                  name="amount"
                  labelWidth={40}
                  label="Valor"
                  startAdornment={<>R$&nbsp;</>}
                  fullWidth
                />
              </PageItem>
              <PageItem paper={false} xs={12}>
                <Input
                  type="number"
                  name="type_card_id"
                  label="ID do Tipo de Cartão"
                  labelWidth={155}
                  fullWidth
                />
              </PageItem>
              <PageItem paper={false} xs={12}>
                <Button xs={6} onClick={handleSubmit(onSubmit)}>
                  {btnText}
                </Button>
              </PageItem>
            </Grid>
          </Form>
        </PageItem>
      </PageContent>
    </Page>
  )
}

CreateOrUpdate.propTypes = {
  match: PropTypes.object.isRequired,
}

export default CreateOrUpdate
