import React from 'react'

import PropTypes from 'prop-types'

import { PageContentStyled } from './styles'

function PageContent({ children, spacing }) {
  return (
    <PageContentStyled container spacing={spacing}>
      {children}
    </PageContentStyled>
  )
}

PageContent.defaultProps = {
  spacing: 0,
}

PageContent.propTypes = {
  children: PropTypes.node.isRequired,
  spacing: PropTypes.number,
}

export default PageContent
