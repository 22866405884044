import React from 'react'

import i18n from '~/commons/i18n'
import { formatDate } from '~/commons/utils/date'
import { formatCurrencyInterger } from '~/commons/utils/money'
import { Link } from '~/components'

const orderColunms = [
  {
    title: 'ID',
    field: 'id',
  },
  {
    title: 'ID Ext',
    field: 'external_id',
  },
  {
    title: 'ID E1',
    field: 'id_order_e1',
  },
  {
    title: 'Data',
    render: (row) => formatDate(row.created_at),
  },
  {
    title: 'Usuário',
    field: 'user.card_number',
  },
  {
    title: 'Valor',
    render: (row) => formatCurrencyInterger(row.amount),
  },
  {
    title: 'Status',
    render: (row) => {
      return i18n.t(`order.chips.${row.status}`)
    },
  },
  {
    title: '',
    render: (row) => <Link to={`/order/${row.external_id}`} />,
  },
]

export { orderColunms }
