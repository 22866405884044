import Api from './api'

const path = '/order'

const order = {
  getAll(page = 1, perPage = 10) {
    return Api.request(`${path}?page=${page}&per_page=${perPage}`)
  },
  show(id) {
    return Api.request(`${path}/${id}`)
  },
}

export default order
