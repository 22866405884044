import React from 'react'

import i18n from '~/commons/i18n'
import { getDate, formatDate } from '~/commons/utils/date'
import { formatCurrencyInterger } from '~/commons/utils/money'
import { Link } from '~/components'

import TextAmount from './components/TextAmount'

function isRecharge(row) {
  return (
    row.type.description === 'Crédito Venda' ||
    row.type.origin === 'EVENTO_RECARGA'
  )
}

function busLineVerify(row) {
  return row.busLine !== 'undefined'
    ? row.busLine
    : row.type.description.split(' ')[0]
}

const utilizationColumns = [
  {
    title: 'Data',
    render: (row) => getDate(row.dateTime).format('DD/MM/YYYY HH:mm:ss'),
  },
  {
    title: 'Linha',
    render: (row) => busLineVerify(row),
  },
  {
    title: '',
    render: (row) => <TextAmount text={row.amount} moneyOn={isRecharge(row)} />,
  },
]

const orderColunms = [
  {
    title: 'ID',
    field: 'id',
  },
  {
    title: 'ID Ext',
    field: 'external_id',
  },
  {
    title: 'ID E1',
    field: 'id_order_e1',
  },
  {
    title: 'Data',
    render: (row) => formatDate(row.created_at),
  },
  {
    title: 'Valor',
    render: (row) => formatCurrencyInterger(row.amount),
  },
  {
    title: 'Status',
    render: (row) => {
      return i18n.t(`order.chips.${row.status}`)
    },
  },
  {
    title: '',
    render: (row) => <Link to={`/order/${row.external_id}`} />,
  },
]

export { utilizationColumns, orderColunms }
