import React from 'react'

import PropTypes from 'prop-types'

import { PageFooterStyled } from './styles'

function PageFooter({ children }) {
  return <PageFooterStyled>{children}</PageFooterStyled>
}

PageFooter.defaultProps = {
  children: null
}

PageFooter.propTypes = {
  children: PropTypes.node
}

export default PageFooter
