import React from 'react'
import { FormContext } from 'react-hook-form'

import PropTypes from 'prop-types'

function Form({ children, onSubmit, methods, ...props }) {
  return (
    <FormContext {...methods}>
      <form onSubmit={onSubmit} {...props}>
        {children}
      </form>
    </FormContext>
  )
}

Form.defaultProps = {
  onSubmit: null,
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  methods: PropTypes.object.isRequired,
}

export default Form
