import React, { Suspense } from 'react'
import { Switch, BrowserRouter, Route } from 'react-router-dom'

import { PrivateRoutes, NotFound, Loading } from '~/components'

import privateRoutes from './private'
import publicRoutes from './public'

function Routes() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Switch>
          {publicRoutes.map(({ path, element, exact, ...rest }) => (
            <Route key={path} path={path} component={element} exact {...rest} />
          ))}
          {privateRoutes.map(({ path, element, exact, ...rest }) => (
            <PrivateRoutes
              key={path}
              path={path}
              component={element}
              exact
              {...rest}
            />
          ))}
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export default Routes
