import React, { useState } from 'react'

import { OutlinedInput as MuiInput, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import { withHookForm } from '~/components/Form/HookForm'

function InputPassword(props) {
  const [showPassword, setShowPassword] = useState(false)

  const Adornment = (
    <IconButton onClick={() => setShowPassword(!showPassword)}>
      {showPassword ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  )

  return (
    <MuiInput
      {...props}
      type={showPassword ? 'text' : 'password'}
      endAdornment={Adornment}
    />
  )
}

export { InputPassword }
export default withHookForm(InputPassword)
