import React from 'react'

import { Done as DoneIcon, Close as CloseIcon } from '@material-ui/icons'

import { formatDate } from '~/commons/utils/date'
import { Link } from '~/components'

const orderColunms = [
  {
    title: 'ID Cartão',
    field: 'CRU_ID',
  },
  {
    title: 'Nome',
    field: 'USU_NOME',
  },
  {
    title: 'CPF',
    field: 'USU_CPF',
  },
  {
    title: 'Serie',
    field: 'CRU_NRS_CARTAO',
  },
  {
    title: 'Dt. Nascimento',
    render: (row) => formatDate(row.USU_DT_NASCTO),
  },
  {
    title: 'Status Cadastro',
    render: (row) =>
      row.isExistInInternalDatabase ? (
        <DoneIcon color="primary" />
      ) : (
        <CloseIcon color="secondary" />
      ),
  },
  {
    title: '',
    render: (row) => <Link to={`/client/${row.CRU_NRS_CARTAO}`} />,
  },
]

export { orderColunms }
