import React from 'react'

import Typography from '@material-ui/core/Typography'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from '@material-ui/lab'
import PropTypes from 'prop-types'

import { getOrderColor, getOrderIcon } from '~/commons/constants/order'
import i18n from '~/commons/i18n'
import { Paper } from '~/components'

export default function CustomizedTimeline({ timelineContent }) {
  return (
    <Timeline align="alternate">
      {timelineContent?.map((content, idx) => {
        return (
          <TimelineItem key={content.id}>
            <TimelineOppositeContent>
              <Typography color="textSecondary">
                {content.created_at}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color={getOrderColor(content.order_event)}>
                {getOrderIcon(content.order_event)}
              </TimelineDot>
              {idx + 1 < timelineContent.length ? <TimelineConnector /> : ''}
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} style={{ padding: '6px 16px' }}>
                <Typography variant="h6">
                  {i18n.t(`order.tracking.${content.order_event}`)}
                </Typography>
                <Typography>{content.message}</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        )
      })}
    </Timeline>
  )
}

CustomizedTimeline.propTypes = {
  timelineContent: PropTypes.shape.isRequired,
}
