/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'

import { Skeleton } from '@material-ui/lab'
import PropTypes from 'prop-types'

import i18n from '~/commons/i18n'
import { formatDate } from '~/commons/utils/date'
import { formatCurrencyInterger } from '~/commons/utils/money'
import { Page, PageContent, PageItem, ListText } from '~/components'
import { orderApi } from '~/services/ubertrans-api'

import Timeline from './components/Timeline'

function ListOrder({ match }) {
  const [order, setOrder] = useState({})
  const { idOrder } = match.params

  useEffect(() => {
    async function fetchData() {
      await orderApi
        .show(idOrder)
        .then((response) => response.pop())
        .then((response) => {
          setOrder(response)
        })
    }
    fetchData()
  }, [idOrder])
  return (
    <Page title={`Pedido #${order?.external_id}`}>
      <PageContent spacing={2}>
        <PageItem paper xs={12} sm={4} style={{ padding: '4%' }}>
          {order.id_order_e1 && (
            <ListText
              primaryText={order.id_order_e1}
              captionText={i18n.t('order.idE1')}
            />
          )}
          <ListText
            primaryText={order.user?.card_number}
            captionText={i18n.t('order.numberSerieCard')}
          />
          <ListText
            primaryText={formatDate(order.created_at, 'DD/MM/YYYY HH:mm:ss')}
            captionText={i18n.t('order.date')}
          />
          <ListText
            primaryText={formatCurrencyInterger(order.amount)}
            captionText={i18n.t('order.orderPrice')}
          />
        </PageItem>
        <PageItem paper xs={12} sm={4} style={{ padding: '4%' }}>
          {order.transaction ? (
            <>
              <ListText
                primaryText={order.transaction?.tid}
                captionText={i18n.t('order.transactionID')}
              />
              <ListText
                primaryText={order.transaction?.nsu}
                captionText={i18n.t('order.nsu')}
              />
              <ListText
                primaryText={formatDate(
                  order.transaction?.created_at,
                  'DD/MM/YYYY HH:mm:ss'
                )}
                captionText={i18n.t('order.transactionDate')}
              />
              <ListText
                primaryText={formatCurrencyInterger(order.transaction?.amount)}
                captionText={i18n.t('order.amountTransaction')}
              />
            </>
          ) : (
            <>
              <Skeleton width="60%" animation={false} />
              <Skeleton animation={false} />
              <Skeleton width="60%" animation={false} />
              <Skeleton animation={false} />
              <Skeleton width="60%" animation={false} />
              <Skeleton animation={false} />
              <Skeleton width="60%" animation={false} />
              <Skeleton animation={false} />
            </>
          )}
        </PageItem>
        <PageItem paper xs={12} sm={4} style={{ padding: '4%' }}>
          {order.transaction && order.transaction.card_bin ? (
            <>
              <ListText
                primaryText={`${order.transaction?.card_bin}******${order.transaction?.last4}`}
                captionText={i18n.t('order.cardNumber')}
              />
              <ListText
                primaryText={order.transaction?.authorization_code}
                captionText={i18n.t('order.authorizationCode')}
              />
            </>
          ) : (
            <>
              <Skeleton width="60%" animation={false} />
              <Skeleton animation={false} />
              <Skeleton width="60%" animation={false} />
              <Skeleton animation={false} />
            </>
          )}
        </PageItem>
        <PageItem paper xs={12} style={{ padding: '4%', marginTop: '2%' }}>
          <Timeline
            timelineContent={order?.tracking?.map((tracking) => ({
              ...tracking,
              created_at: formatDate(
                tracking.created_at,
                'DD/MM/YYYY HH:mm:ss'
              ),
            }))}
          />
        </PageItem>
      </PageContent>
    </Page>
  )
}

export default ListOrder

ListOrder.propTypes = {
  match: PropTypes.object.isRequired,
}
