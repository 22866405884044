import React from 'react'

import { OutlinedInput as MuiOutlinedInput } from '@material-ui/core'

import { withHookForm } from '~/components/Form/HookForm'

function OutlinedInput(props) {
  return <MuiOutlinedInput {...props} />
}

export { OutlinedInput }
export default withHookForm(OutlinedInput)
