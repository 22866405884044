import Api from './api'

const path = '/client'

const users = {
  getAll(data) {
    return Api.request(`${path}/searchClient`, { method: 'POST', data })
  },
  show(id) {
    return Api.request(`${path}/${id}`)
  },
  getExtract(id, interval = 30) {
    return Api.request(`${path}/${id}/extract?interval=${interval}`)
  },
  getBalance(id) {
    return Api.request(`${path}/${id}/balance`)
  },
  getAreas(id) {
    return Api.request(`${path}/${id}/areas`)
  },
  getOrders(id, page = 1, perPage = 10) {
    return Api.request(`${path}/${id}/orders?page=${page}&per_page=${perPage}`)
  },
}

export default users
