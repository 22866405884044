import React from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import GlobalStyle from '~/commons/styles/global'
import { CartProvider } from '~/contexts/cart'

function Ubertrans({ children, theme }) {
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyle />
        <CartProvider>{children}</CartProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

Ubertrans.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
}

export default Ubertrans
