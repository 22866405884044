import React, { createContext, useState } from 'react'

import PropTypes from 'prop-types'

import { getUnmask } from '~/commons/utils/mask'
import { orderApi } from '~/services/ubertrans-api'

const CartContext = createContext({
  planId: '',
  transactionType: 'creditCard',
  card: {
    number: '',
    holderName: '',
    expirationDate: '',
    securityCode: '',
  },
})

export const CartProvider = ({ children }) => {
  const [planId, setPlanId] = useState('')
  const [transactionType, setTransactionType] = useState('creditCard')
  const [card, setCard] = useState({
    number: '',
    holderName: '',
    expirationDate: '',
    securityCode: '',
  })

  const submitCart = () => {
    return orderApi.store({
      planId,
      transactionType,
      card: {
        cardNumber: getUnmask('creditCardNumber')(card.number),
        securityCode: card.securityCode,
        expirationYear: card.expirationDate.split('/')[1],
        expirationMonth: card.expirationDate.split('/')[0],
        cardHolderName: card.holderName,
      },
    })
  }

  return (
    <CartContext.Provider
      value={{
        planId,
        setPlanId,
        transactionType,
        setTransactionType,
        card,
        setCard,
        submitCart,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export default CartContext

CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
