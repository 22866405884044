/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import i18n from '~/commons/i18n'
import { formatDate } from '~/commons/utils/date'
import { formatCurrency } from '~/commons/utils/money'
import {
  Page,
  PageContent,
  PageItem,
  ListText,
  CardWithCollapse,
  Typography,
  Table,
  Paper,
} from '~/components'
import { clientApi } from '~/services/ubertrans-api'

import { utilizationColumns, orderColunms } from './constants'

function ListClient({ match }) {
  const [client, setClient] = useState({
    CRU_ID: 0,
    CRU_NRS_CARTAO: 0,
    USU_CPF: '00000000000',
    USU_DT_NASCTO: '2000-01-01',
    USU_NOME: 'NOME',
  })
  // eslint-disable-next-line no-unused-vars
  const [isExistInInternalDatabase, setIsExistInInternalDatabase] = useState(
    false
  )
  const [extract, setExtract] = useState([])
  const [balance, setBalance] = useState({ totalBalance: 0, data: [] })

  const { idClient } = match.params

  useEffect(() => {
    async function fetchData() {
      await clientApi.show(idClient).then((response) => {
        setClient(response)
        setIsExistInInternalDatabase(response.isExistInInternalDatabase)
      })

      clientApi.getBalance(idClient).then((response) => {
        setBalance(response)
      })
      clientApi.getExtract(idClient).then((response) => {
        setExtract(response)
      })
    }
    fetchData()
  }, [idClient])

  // useEffect(() => {
  //   async function fetchData() {
  //     await clientApi.getOrders(idClient).then((response) => {
  //       setOrders(response)
  //     })
  //   }
  //   if (isExistInInternalDatabase) {
  //     fetchData()
  //   }
  // }, [idClient, isExistInInternalDatabase])
  return (
    <Page title={`Cliente #${client?.CRU_NRS_CARTAO}`}>
      <PageContent spacing={2}>
        <PageItem paper xs={12} sm={4} style={{ padding: '2%' }}>
          <ListText
            primaryText={client?.CRU_ID}
            captionText={i18n.t('client.idCard')}
          />
          <ListText
            primaryText={client?.CRU_NRS_CARTAO}
            captionText={i18n.t('order.numberSerieCard')}
          />
          <ListText
            primaryText={client?.USU_NOME}
            captionText={i18n.t('client.name')}
          />
          <ListText
            primaryText={client?.USU_CPF}
            captionText={i18n.t('client.cpf')}
          />
          <ListText
            primaryText={formatDate(client?.USU_DT_NASCTO, 'DD/MM/YYYY')}
            captionText={i18n.t('client.birthDate')}
          />
        </PageItem>
        <PageItem
          paper={false}
          xs={12}
          sm={2}
          style={{ padding: '2%', marginLeft: 'auto' }}
        >
          <CardWithCollapse
            cardContent={
              <>
                <Typography component="h6" variant="h6">
                  Saldo
                </Typography>
                <Typography component="h5" variant="h5">
                  {formatCurrency(balance.totalBalance)}
                </Typography>
              </>
            }
            cardContentHidden={balance.data.map((areaBalance) => {
              return (
                <ListText
                  key={areaBalance.cardDescription}
                  primaryText={formatCurrency(areaBalance.balance)}
                  captionText={areaBalance.cardDescription}
                />
              )
            })}
          />
        </PageItem>
      </PageContent>
      <PageContent spacing={2}>
        <PageItem paper xs={12} sm={4} style={{ padding: '2%' }}>
          <Table
            data={extract}
            title="Utilizações"
            options={{
              search: false,
              paging: true,
              toolbar: true,
              showTitle: true,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'Não há registros para mostrar',
              },
            }}
            columns={utilizationColumns}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
          />
        </PageItem>
        <PageItem paper xs={12} sm={8} style={{ padding: '2%' }}>
          <Table
            title="Lista de pedidos"
            data={(query) => {
              return new Promise((resolve, reject) => {
                clientApi
                  .getOrders(idClient, query.page + 1, query.pageSize)
                  .then((response) => {
                    resolve({
                      data: response.data,
                      page: response.page - 1,
                      totalCount: response.total,
                    })
                  })
                  .catch((err) => reject(err))
              })
            }}
            options={{
              search: false,
              paging: true,
              toolbar: true,
              showTitle: true,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'Não há registros para mostrar',
              },
            }}
            columns={orderColunms}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
          />
        </PageItem>
      </PageContent>
    </Page>
  )
}

export default ListClient

ListClient.propTypes = {
  match: PropTypes.object.isRequired,
}
