import styled from 'styled-components'

import { Grid } from '~/components'

export const PageContentStyled = styled(Grid)`
  flex: 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: ${({ theme }) => theme.spacing(1)}px;
`
