import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    status: {
      primary: '#3498db',
      success: '#27ae60',
      danger: '#c0392b',
      warning: '#f39c12',
    },
  },
  shape: {
    drawerWidth: 240,
  },
})

export default theme
