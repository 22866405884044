import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import PropTypes from 'prop-types'

import { isAuthenticated } from '~/services/auth'

function PrivateRoutes({ permissions, ...rest }) {
  if (!isAuthenticated()) {
    return <Redirect to="/login" />
  }

  if (permissions !== null) {
    return <Redirect to="/" />
  }

  return <Route {...rest} />
}

PrivateRoutes.defaultProps = {
  permissions: null
}

PrivateRoutes.propTypes = {
  permissions: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default PrivateRoutes
