import api from '~/config/api/base'
import { onResponseError, validateRequest } from '~/config/api/interceptors'

const Api = api(
  `${process.env.REACT_APP_API_URL}`,
  {},
  { onResponseError, validateRequest }
)

export default Api
