import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Search as SearchIcon } from '@material-ui/icons'
import * as yup from 'yup'

import { Page, PageContent, PageItem, Table, Paper } from '~/components'
import { Input, Form } from '~/components/Form'
import { clientApi } from '~/services/ubertrans-api'

import { orderColunms } from './constants'

function ListOrder() {
  const [clients, setClients] = useState([])
  const validationSchema = yup.object().shape({
    omniSearch: yup
      .string()
      .min(3, 'Mínimo 3 caracteres')
      .required('Campo obrigatório'),
  })

  const methods = useForm({ validationSchema })
  const { handleSubmit } = methods
  const onSubmit = (values) => {
    clientApi
      .getAll(values)
      .then((response) => {
        return response.externalRepository.map((userExt) => {
          const isExistInInternalDatabase = response.internalRepository.some(
            (userInt) => {
              return userInt.card_number === `${userExt.CRU_NRS_CARTAO}`
            }
          )
          return { ...userExt, isExistInInternalDatabase }
        })
      })
      .then((response) => setClients(response))
  }
  return (
    <Page title="Clientes">
      <PageContent>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          methods={methods}
          style={{ flex: 1 }}
        >
          <PageItem paper xs={6} style={{ padding: '2%', margin: 'auto' }}>
            <Input
              name="omniSearch"
              label="Nome do Cliente ou Número de Série"
              type="text"
              labelWidth={280}
              fullWidth
              autoComplete={false}
              endAdornment={
                <SearchIcon
                  color="primary"
                  style={{ cursor: 'pointer' }}
                  onClick={handleSubmit(onSubmit)}
                />
              }
            />
          </PageItem>
        </Form>
        <PageItem paper={false} xs={12} style={{ padding: '4%' }}>
          <Table
            title="Pesquisa de Clientes"
            data={clients}
            options={{
              search: false,
              paging: true,
              toolbar: true,
              showTitle: true,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'Não há registros para mostrar',
              },
            }}
            columns={orderColunms}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
          />
        </PageItem>
      </PageContent>
    </Page>
  )
}

export default ListOrder
